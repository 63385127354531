import ReactGA from "react-ga4";
import React, { useState, useEffect, useRef } from 'react';
// import { ExternalLink } from 'lucide-react';
import { useLocation } from 'wouter';
import { Auth } from 'aws-amplify';
import fetchUserAttributes from "./components/fetchUserAttributes";
import decrypt_uuid from "./components/decryptUuid";
// import { fetchKeys } from './components/fetchKeys';
import './PlaceReport.css';

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const getHoodGeokey = (reportData) => {
  return reportData.hood?.hood_geokey || reportData.geokey;
};

const ExternalLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}<span className="external-link-icon">↗</span>
  </a>
);

const PlaceReport = () => {
  const [location] = useLocation();
  const [reportData, setReportData] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState('');
  const [selectedScore, setSelectedScore] = useState('ENSEMBLE');
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [featureChartBlob, setFeatureChartBlob] = useState(null);
  const [scoreChartBlob, setScoreChartBlob] = useState(null);
  const [featureChartSrc, setFeatureChartSrc] = useState('');
  const [scoreChartSrc, setScoreChartSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const reportRef = useRef(null);

  const charge_id = new URLSearchParams(window.location.search).get('charge_id');

  const currentDate = new Date().toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  useEffect(() => {
    const handlePrint = () => {
      if (reportRef.current) {
        const title = reportData?.title || "SiteData.io";
        document.title = title; // Set document title dynamically
        window.print(); // Trigger the print dialog
      }
    };
  
    window.addEventListener('beforeprint', handlePrint);
  
    return () => {
      window.removeEventListener('beforeprint', handlePrint);
    };
  }, []);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location });
  }, [location]);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await fetchUserAttributes(user.username);
        setUserId(attributes.sub);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please try logging in again.');
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchReportData = async () => {
      if (!charge_id || !userId) {
        console.log('Missing charge_id or userId, skipping fetch');
        return;
      }

      try {
        // const keys = await fetchKeys();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/report/by_geokey_report`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-SHARED-KEY': decrypt_uuid(process.env.REACT_APP_RANDO),
          },
          body: JSON.stringify({ user_id: userId, charge_id }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setReportData(data);
        setSelectedFeature(getDefaultFeature(data.features_combo));

        // Get initial charts and store them as state variables
        await fetchChart('feature', getDefaultFeature(data.features_combo), setFeatureChartBlob);
        await fetchChart('score', 'ENSEMBLE', setScoreChartBlob);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching report data:', error);
        setError('Failed to fetch report data. Please try again later.');
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchReportData();
    }
  }, [userId, charge_id]);

  const Tooltip = ({ content, children, width = 80 }) => (
    <span className="tooltip-container">
      {children}
      <span className="tooltip-icon">ⓘ</span>
      <span className="tooltip-content" style={{ width: `${width}px`, maxWidth: `${width}px` }}>
        {content}
      </span>
    </span>
  );

  const getDefaultFeature = (features) => {
    const defaultFeatures = ['ZHomeValue', 'BLSUnemployment', 'PerCapitaIncome', 'PerCapitaIncome5Yr'];
    
    for (let defaultFeature of defaultFeatures) {
      const found = features.find(f => f.value === defaultFeature);
      if (found) return found.value;
    }
    
    return features[0]?.value;
  };

  const renderPlaceInfo = () => {
    if (!reportData || !reportData.place) return null;

    const healthTooltip = `
      LHS subtype "health" measures (0 to 1) the socio-economic vitality of a locality.
      "Health" tends to be stable, year to year.
    `;

    const featureTooltip = `
      LHS subtype "feature" (ranging from 0 to 1) can be utilized as a powerful
      feature in ML/AI-based credit, actuarial or marketing models. It's sensitive 
      to data trends over a two-year period, which may cause year-to-year variability.
    `;

    return (
      <>
        <p className="export-links">
          <strong>Export As:</strong>&nbsp;
          <a href="#" onClick={handleDownloadJSON}>JSON</a>
        </p>
        {('hood' in reportData) && (
          <p><strong>Neighborhood Code:</strong> {getHoodGeokey(reportData)}</p>
        )}
        <p><strong>FIPS Code:</strong> {reportData.geokey}</p>
        <p>
          <strong>Health:</strong>{' '}
          <span className="info-with-icon">
            <Tooltip content={healthTooltip.trim()} width={300}>
              {reportData.health.toFixed(2)}
            </Tooltip>
            <img src="/images/icons/steth.png" alt="Health" className="source-icon" />
          </span>
        </p>
        <p>
          <strong>Feature:</strong>{' '}
          <span className="info-with-icon">
            <Tooltip content={featureTooltip.trim()} width={300}>
              {reportData.feature.toFixed(2)}
            </Tooltip>
            <img src="/images/icons/robot.png" alt="Feature" className="source-icon" />
          </span>
        </p>
        {Object.entries(reportData.place).map(([key, value]) => (
          <p key={key}><strong>{key}:</strong> {value}</p>
        ))}
      </>
    );
  };

  const gridSettings = {
    scores: {
      tableWidth: 800,  // Increased width to accommodate new column
      columns: [
        { name: 'Locality Health Score', width: 300 },
        { name: '', width: 150 },
        { name: '', width: 150 },
        { name: '% Change', width: 150 }
      ]
    },
    readings: {
      tableWidth: 800,  // Increased width to accommodate new column
      columns: [
        { name: 'Reading', width: 300 },
        { name: 'Year Earlier', width: 150 },
        { name: 'Date', width: 150 },
        { name: '% Change', width: 150 }
      ]
    }
  };

  const formatNumber = (value) => {
    if (value === null || isNaN(value)) return value;
    const numValue = parseFloat(value);
    return Math.abs(numValue) >= 1000 ? numValue.toLocaleString('en-US', { maximumFractionDigits: 2 }) : numValue.toString();
  };

  const calculatePercentChange = (oldValue, newValue) => {
    if (oldValue === null || newValue === null || isNaN(oldValue) || isNaN(newValue)) {
      return null;
    }
    const change = ((newValue - oldValue) / oldValue) * 100;
    return change === 0 ? null : change.toFixed(2);
  };

  const formatPercentChange = (percentChange) => {
    if (percentChange === null) {
      return <span className="percent-change no-change">---</span>;
    }
    const formattedValue = `${percentChange} %`;
    return <span className={`percent-change ${percentChange > 0 ? 'positive' : 'negative'}`}>{formattedValue}</span>;
  };

  const renderScoresGrid = () => {
    if (!reportData || !reportData.raw_data || !reportData.raw_data.scores) return null;

    const scores = reportData.raw_data.scores;
    const dates = scores[Object.keys(scores)[0]].dates;
    const sortedScores = Object.entries(scores).sort(([a], [b]) => a.localeCompare(b));

    gridSettings.scores.columns[1].name = dates[0];
    gridSettings.scores.columns[2].name = dates[1];

    return (
      <div className="data-grid">
        <table style={{ width: `${gridSettings.scores.tableWidth}px` }}>
          <thead>
            <tr>
              {gridSettings.scores.columns.map((col, index) => (
                <th key={index} style={{ width: `${col.width}px` }}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedScores.map(([name, data]) => {
              const percentChange = calculatePercentChange(data.values[0], data.values[1]);
              return (
                <tr key={name}>
                  <td>{name}</td>
                  {data.values.map((value, index) => (
                    <td key={index} style={{ textAlign: 'right' }}>{formatNumber(value)}</td>
                  ))}
                  <td style={{ textAlign: 'right' }}>{formatPercentChange(percentChange)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderReadingsGrid = () => {
    if (!reportData || !reportData.raw_data || !reportData.raw_data.readings || !reportData.features_combo) return null;

    const readings = reportData.raw_data.readings;
    const sortedReadings = Object.entries(readings).sort(([a], [b]) => a.localeCompare(b));

    const featureInfoMap = {};
    reportData.features_combo.forEach(feature => {
      featureInfoMap[feature.display] = feature;
    });

    return (
      <div className="data-grid">
        <table style={{ width: `${gridSettings.readings.tableWidth}px` }}>
          <thead>
            <tr>
              {gridSettings.readings.columns.map((col, index) => (
                <th key={index} style={{ width: `${col.width}px` }}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedReadings.map(([name, data]) => {
              const percentChange = calculatePercentChange(data.values[0], data.values[1]);
              const featureInfo = featureInfoMap[name];
              const url = featureInfo?.url;
              return (
                <tr key={name}>
                  <td>
                    {featureInfo?.display || name}
                    {url && (
                      <a href={url} target="_blank" rel="noopener noreferrer" className="external-link-icon" style={{ marginLeft: '5px' }}>
                        <ExternalLink size={14} />
                      </a>
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Tooltip content={data.dates[0]}>
                      {formatNumber(data.values[0])}
                    </Tooltip>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Tooltip content={data.dates[1]}>
                      {formatNumber(data.values[1])}
                    </Tooltip>
                  </td>
                  <td style={{ textAlign: 'right' }}>{formatPercentChange(percentChange)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const fetchChart = async (type, selected, setChartBlob) => {
    const keys = {}; // await fetchKeys();
    const config = generateChartConfig(type, selected, keys);
    if (!config) return;
  
    try {
      const response = await fetch(config.url, { headers: config.headers });
      const blob = await response.blob();
      console.log(`${type} chart blob received. Size:`, blob.size);
      setChartBlob(blob);  
    } catch (error) {
      console.error(`Error fetching ${type} chart:`, error);
    }
  };

  const generateChartConfig = (type, selected, keys) => {
    if (!reportData) return null;
    const baseUrl = `${process.env.REACT_APP_BACKEND_SERVER}/chart/generate`;
    const params = new URLSearchParams({
      geokey: getHoodGeokey(reportData),
      feature: type === 'feature' ? selected : selectedScore,
      width_px: 800,
      height_px: 400
    });
  
    return {
      url: `${baseUrl}?${params.toString()}`,
      headers: {
        "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
      }
    };
  };

  // Create object URL and set as chart source when blob changes
  useEffect(() => {
    if (featureChartBlob) {
      const objectUrl = URL.createObjectURL(featureChartBlob);
      setFeatureChartSrc(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
        setFeatureChartSrc(''); // Clear the src when cleaning up
      };
    }
  }, [featureChartBlob]);

  useEffect(() => {
    if (scoreChartBlob) {
      const objectUrl = URL.createObjectURL(scoreChartBlob);
      setScoreChartSrc(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
        setScoreChartSrc(''); // Clear the src when cleaning up
      };
    }
  }, [scoreChartBlob]);

  useEffect(() => {
    if (reportData && selectedFeature) {
      console.log('Fetching initial feature chart');
      fetchChart('feature', selectedFeature, setFeatureChartBlob);
    }
  }, [reportData, selectedFeature]);
  
  useEffect(() => {
    if (reportData && selectedScore) {
      console.log('Fetching initial score chart');
      fetchChart('score', selectedScore, setScoreChartBlob);
    }
  }, [reportData, selectedScore]);

  const handleDownloadJSON = async (e) => {
    e.preventDefault();
    try {
      // const keys = await fetchKeys();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/report/by_geokey_report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-SHARED-KEY': decrypt_uuid(process.env.REACT_APP_RANDO),
        },
        body: JSON.stringify({ user_id: userId, charge_id, just_json: 'true' }),
      });
      if (!response.ok) throw new Error('Failed to fetch JSON');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${getHoodGeokey(reportData)}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading JSON:', error);
    }
  };

  if (error) return <div className="error-message">{error}</div>;
  if (isLoading) return (
    <div className="loading-message">
      <div className="spinner"></div>
      Generating report...
    </div>
  );

  return (
    <div className="place-report" ref={reportRef}>
        <h1>{reportData.title}</h1>
        <table className="report-table">
          <tbody>
            <tr>
              <td className="map-cell">
                <div className="map-container">
                  <iframe
                    width="400"
                    height="300"
                    style={{border:0}}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={reportData.google_embed_map}
                    title={`Map of ${reportData.title}`}
                  ></iframe>
                </div>
              </td>
              <td className="info-cell">
                <div className="info-container">
                  {renderPlaceInfo()}
                  {(reportData.latitude && reportData.longitude) && (
                    <p><strong>Lat, Lng:</strong> {reportData.latitude}, {reportData.longitude}</p>
                  )}
                  {reportData.gniscodes && reportData.gniscodes.length > 0 && (
                    <p>
                      <strong>GNIS IDs:</strong> {' '}
                      {reportData.gniscodes.map((code, index) => (
                        <React.Fragment key={index}>
                          <ExternalLink href={code}>
                            {code.split('names/')[1]}
                          </ExternalLink>
                          {index < reportData.gniscodes.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </p>
                  )}
                  <div className="source-icons">
                    <ExternalLink href={reportData.zillowid}>
                      <img src="/images/icons/zillow.png" alt="Zillow" className="source-icon" />
                    </ExternalLink>
                    <ExternalLink href={reportData.wikiid}>
                      <img src="/images/icons/wikipedia.png" alt="Wikipedia" className="source-icon wikipedia-icon" />
                    </ExternalLink>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <div className="chart-container" style={{ marginBottom: '0' }}>
                  {scoreChartSrc && <img src={scoreChartSrc} alt={`Score Chart: ${reportData.scores_combo.find(s => s.value === selectedScore)?.display}`} />}
                  <label style={{ marginTop: '0' }}>
                    <span className="chart-label"><b>Score:&nbsp;</b></span>
                    <select 
                      value={selectedScore} 
                      onChange={(e) => setSelectedScore(e.target.value)}
                    >
                      {reportData.scores_combo.map((score) => (
                        <option key={score.value} value={score.value}>
                          {score.display}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
            <td colSpan="2">
              <div className="chart-container" style={{ marginTop: '0', marginBottom: '0' }}>
                {featureChartSrc && <img src={featureChartSrc} alt={`Feature Chart: ${reportData.features_combo.find(f => f.value === selectedFeature)?.display}`} />}
                <label style={{ marginTop: '0' }}>
                  <span className="chart-label"><b>Feature:&nbsp;</b></span>
                  <select 
                    value={selectedFeature} 
                    onChange={(e) => setSelectedFeature(e.target.value)}
                  >
                    {reportData.features_combo.map((feature) => (
                      <option key={feature.value} value={feature.value}>
                        {feature.display}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </td>
            </tr>
            <tr>
              <td colSpan="2">
                <hr className="grid-separator" />
                {renderScoresGrid()}
                {renderReadingsGrid()}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="footer">
          <div className="date">{currentDate}</div>
          <img src="/images/SiteData.io.logo.clear.png" alt="SiteData.io Logo" className="site-logo" />
      </div>
    </div>
  );
};

export default PlaceReport;