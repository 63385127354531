import ReactGA from "react-ga4";
import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './swagger-custom.css'; 
import { Auth } from 'aws-amplify';
import decrypt_uuid from "./decryptUuid";
import { useLocation } from 'wouter';

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const AuthenticatedSwaggerUI = () => {
  const [location, setLocation] = useLocation();
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location });
  }, [location]);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(user);
        const sub = attributes.find(attr => attr.Name === 'sub')?.Value;

        if (!sub) {
          console.error('User sub not found');
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_SERVER}/user/get_fields`,
          {
            method: 'POST',
            headers: {
              "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: sub }),
          }
        );

        if (response.ok) {
          const extraFields = await response.json();
          setApiKey(extraFields.api_key || null);
        } else {
          console.error("Failed to fetch API key");
        }
      } catch (error) {
        console.error('Error fetching API key:', error);
      }
    };

    fetchApiKey();
  }, []);

  const requestInterceptor = (req) => {
    if (apiKey) {
      req.headers['X-API-KEY'] = apiKey;
    }
    return req;
  };

  console.log(apiKey)
  if (!apiKey) {
    return <div>Loading...</div>;
  }

  return (
    <div className="swagger-ui-container">
      <SwaggerUI
        url={`${process.env.REACT_APP_BACKEND_SERVER}/openapi.json`}
        requestInterceptor={requestInterceptor}
      />
    </div>
  );
};

export default AuthenticatedSwaggerUI;
