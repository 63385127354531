import ReactGA from "react-ga4";
import React, { useState, useEffect, useRef } from 'react';
import { Search, DollarSign, Eye, RefreshCw, Trash2, Info } from 'lucide-react';
import { useLoadScript } from '@react-google-maps/api';
import { Auth } from 'aws-amplify';
import { useLocation } from 'wouter';
import fetchUserAttributes from "./components/fetchUserAttributes";
//import { fetchKeys } from './components/fetchKeys';
import decrypt_uuid from "./components/decryptUuid";
import './Reports.css';

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const libraries = ['places'];

const Reports = () => {
  const [location, setLocation] = useLocation();
  const [user, setUser] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [balance, setBalance] = useState(0);
  const [queries, setQueries] = useState([]);
  const [address, setAddress] = useState('');
  const [placeDetails, setPlaceDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const autocompleteRef = useRef(null);

  const googleMapsApiKey = 'AI' + process.env.REACT_APP_PWD;

  const REPORT_COST = 1000;

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location });
  }, [location]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
    id: 'google-maps-script',
    async: true,
    defer: true,
    onError: (error) => {
      // Log the error, but don't throw if it's the utc_offset deprecation warning
      if (!error.message.includes('utc_offset is deprecated')) {
        console.error('Google Maps script load error:', error);
      }
    },
  });

  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
        await fetchUserFields(currentUser);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setLocation('/login');
      }
    };

    checkUser();
  }, [setLocation]);

  useEffect(() => {
    let autocomplete;
    if (isLoaded && !loadError) {
      const autocompleteInput = document.getElementById('autocomplete-input');
      if (autocompleteInput && !autocomplete) {
        autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput, {
          types: ['geocode'],
        });
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            setAddress(place.formatted_address);
            setPlaceDetails(place);
          }
        });
      }
    }
    return () => {
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [isLoaded, loadError]);

  const fetchUserFields = async (user) => {
    try {
      const attributes = await fetchUserAttributes(user.username);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER}/user/get_fields`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-SHARED-KEY': decrypt_uuid(process.env.REACT_APP_RANDO),
          },
          body: JSON.stringify({ user_id: attributes.sub }),
        }
      );
      if (response.ok) {
        const extraFields = await response.json();
        setApiKey(extraFields.api_key);
        setBalance(extraFields.balance);
        console.log('Balance:', extraFields.balance);
      }
    } catch (error) {
      console.error('Error fetching user fields:', error);
    }
  };

  const verifyAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    try {
      const result = await geocoder.geocode({ address });
      if (result.results.length > 0) {
        return result.results[0];
      }
    } catch (error) {
      console.error('Geocoding failed:', error);
    }
    return null;
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      setErrorMessage('Google Maps API is not loaded');
      return;
    }
    setIsLoading(true);
    setErrorMessage('');

    try {
      const place = placeDetails || await verifyAddress(address);
      if (!place) {
        throw new Error('Failed to verify address');
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/data/google_geokey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': apiKey,
        },
        body: JSON.stringify(place),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch data');
      }

      const data = await response.json();
      if (data.places && data.places.length > 0) {
        const newQuery = {
          id: data.charge_id,
          title: data.places[0].title,
          tooltip: JSON.stringify(data.places[0], null, 2),
          status: 'find_geokey',
          createdat: new Date().toISOString(),
        };
        setQueries(prevQueries => [...prevQueries, newQuery].sort((a, b) => a.title.localeCompare(b.title)));
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
      setAddress('');
      setPlaceDetails(null);
    }
  };

  const openChargeModal = (query) => {
    if (balance < REPORT_COST) {
      setInsufficientFundsModalOpen(true);
    } else {
      setSelectedQuery(query);
      setChargeModalOpen(true);
    }
  };

  const confirmCharge = async () => {
    if (selectedQuery && balance >= REPORT_COST) {
      setIsProcessing(true);
      setIsLoading(true);
      setErrorMessage('');

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/data/find_to_by_geokey?charge_id=${selectedQuery.id}`, {
          method: 'GET',
          headers: {
            'X-API-KEY': apiKey,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to convert to report');
        }

        const data = await response.json();
        setQueries(prevQueries => prevQueries.map(q => 
          q.id === selectedQuery.id 
            ? { ...q, status: 'by_geokey', id: data.charge_id, tooltip: JSON.stringify(data.header, null, 2) }
            : q
        ).sort((a, b) => a.title.localeCompare(b.title)));

        // Deduct the charge from the balance
        setBalance(prevBalance => prevBalance - REPORT_COST);

        // Open the new report in a new window
        window.open(`/place_report?charge_id=${data.charge_id}`, '_blank', 'noopener,noreferrer');
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
        setIsProcessing(false);
      }
    }
    setChargeModalOpen(false);
  };

  const openDeleteModal = (query) => {
    setSelectedQuery(query);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedQuery) {
      setIsProcessing(true);
      setIsLoading(true);
      setErrorMessage('');

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/report/remove_entry?charge_id=${selectedQuery.id}`, {
          method: 'GET',
          headers: {
            'X-API-KEY': apiKey,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete place');
        }

        setQueries(prevQueries => prevQueries.filter(q => q.id !== selectedQuery.id));
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
        setIsProcessing(false);
      }
    }
    setDeleteModalOpen(false);
  };

  const refreshQueries = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const attributes = await fetchUserAttributes(user.username);
      // const keys = await fetchKeys();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/report/get_user_queries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-SHARED-KEY': decrypt_uuid(process.env.REACT_APP_RANDO),
        },
        body: JSON.stringify({ user_id: attributes.sub }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const updatedQueries = [
        ...(data.by_geokey || []).map(([chargeId, createdat, spec]) => ({
          id: chargeId,
          title: spec.title,
          tooltip: JSON.stringify(spec, null, 2),
          status: 'by_geokey',
          createdat,
        })),
        ...(data.find_geokey || [])
        .concat(data.find_address || [])
        .map(([chargeId, createdat, spec]) => ({
          id: chargeId,
          title: spec[0].title,
          tooltip: JSON.stringify(spec[0], null, 2),
          status: 'find_geokey',
          createdat,
        })),
      ].sort((a, b) => a.title.localeCompare(b.title));
      setQueries(updatedQueries);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setErrorMessage('Failed to fetch reports. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user && apiKey) {
      refreshQueries();
    }
  }, [user, apiKey]);

  if (loadError) return <div>Error loading Google Maps API: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading Google Maps API...</div>;

  return (
    <div className="fancy-page">
      <h2>
        Generate and View Reports
        <span className="info-icon-container">
          <Info size={18} className="info-icon" />
          <div class="info-tooltip">
            <p>1. Search for locality</p>
            <p>2. Report: Click $ to purchase</p>
            <p>3. Report: Click eye to view</p>
          </div>
        </span>
      </h2>
      <form onSubmit={handleSearchSubmit} className="fancy-form">
        <input
          id="autocomplete-input"
          className="autocomplete-input"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter neighborhood, city, or street address"
          required
        />
        <button type="submit" className="fancy-button" disabled={isLoading}>
          <Search className="fancy-icon" /> Search
        </button>
      </form>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <table className="fancy-table">
        <thead>
          <tr>
            <th>Neighborhood, City or Street Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {queries.map((query) => (
            <tr key={query.id}>
              <td>
                <div className="tooltip-container">
                  {query.title}
                  <span className="tooltip">{query.tooltip}</span>
                </div>
              </td>
              <td>
                {query.status === 'by_geokey' ? (
                  <div className="button-tooltip-container">
                    <a href={`/place_report?charge_id=${query.id}`} target="_blank" rel="noopener noreferrer">
                      <button className="fancy-button">
                        <Eye className="fancy-icon" />
                      </button>
                    </a>
                    <span className="button-tooltip">View report</span>
                  </div>
                ) : (
                  <div className="button-tooltip-container">
                    <button
                      className="fancy-button"
                      onClick={() => openChargeModal(query)}
                      disabled={isLoading}
                    >
                      <DollarSign className="fancy-icon" />
                    </button>
                    <span className="button-tooltip">Purchase report</span>
                  </div>
                )}
                <div className="button-tooltip-container">
                  <button
                    className="fancy-button"
                    onClick={() => openDeleteModal(query)}
                    disabled={isLoading}
                  >
                    <Trash2 className="fancy-icon" />
                  </button>
                  <span className="button-tooltip">Delete place</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="button-tooltip-container">
        <button onClick={refreshQueries} className="fancy-button refresh-button" disabled={isLoading}>
          <RefreshCw className="fancy-icon" />{" "}Refresh
        </button>
        <span className="button-tooltip">Refresh places list</span>
      </div>

      {deleteModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete this place?</p>
            {isProcessing ? (
              <div className="spinner"></div>
            ) : (
              <>
                <button onClick={confirmDelete}>Yes, Delete</button>
                <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
              </>
            )}
          </div>
        </div>
      )}

      {chargeModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Charge</h3>
            <p>Your current balance: ${(balance / 100).toFixed(2)}</p>
            <p>Are you sure you want to create this report? Your credit balance will be debited $10.</p>
            {isProcessing ? (
              <div className="spinner"></div>
            ) : (
              <>
                <button onClick={confirmCharge}>Yes, Charge $10</button>
                <button onClick={() => setChargeModalOpen(false)}>Cancel</button>
              </>
            )}
          </div>
        </div>
      )}

      {insufficientFundsModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Insufficient Funds</h3>
            <p>Your current balance: ${(balance / 100).toFixed(2)}</p>
            <p>Contact SiteData.io to refill your credit balance.</p>
            <button className="fancy-button" style={{backgroundColor: 'red'}} onClick={() => setInsufficientFundsModalOpen(false)}>OK</button>
          </div>
        </div>
      )}

    </div>
  );
};

export default Reports;