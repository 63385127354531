import ReactGA from "react-ga4";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Auth } from "aws-amplify";
import fetchUserAttributes from "./components/fetchUserAttributes";
// import { fetchKeys } from './components/fetchKeys';
import decrypt_uuid from "./components/decryptUuid";
import "./Profile.css";
import { useLoadScript } from '@react-google-maps/api';
import { useLocation } from 'wouter';

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
const libraries = ['places'];

const Profile = () => {
  const [location, setLocation] = useLocation();
  const [user, setUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState({});
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [company, setCompany] = useState("");
  const [notes, setNotes] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [balance, setBalance] = useState(0);
  const [apiKey, setApiKey] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const autocompleteRef = useRef(null);

  const googleMapsApiKey = 'AI' + process.env.REACT_APP_PWD;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
    id: 'google-maps-script',
    async: true,
    defer: true,
    onError: (error) => {
      if (!error.message.includes('utc_offset is deprecated')) {
        console.error('Google Maps script load error:', error);
      }
    },
  });

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location });
  }, [location]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await fetchUserAttributes(user.username);
        // const keys = await fetchKeys();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_SERVER}/user/get_fields`,
          {
            method: 'POST',
            headers: {
              "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: attributes.sub }),
          }
        );
        if (response.ok) {
          const extraFields = await response.json();
          setUserAttributes({ ...attributes, ...extraFields });
          setPhone(extraFields.phone || "");
          setAddress(extraFields.address || "");
          setCompany(extraFields.company || "");
          setNotes(extraFields.notes || "");
          setSubscribe(extraFields.subscribe !== undefined ? extraFields.subscribe : true);
          setBalance(extraFields.balance || 0);
          setApiKey(extraFields.api_key || "");
        } else {
          console.error("Failed to fetch extra fields");
          setUserAttributes(attributes);
        }
        setUser(user);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    let autocomplete;
    if (isLoaded && !loadError) {
      const autocompleteInput = document.getElementById('autocomplete-input');
      if (autocompleteInput && !autocomplete) {
        autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput, {
          types: ['geocode'],
        });
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            setAddress(place.formatted_address);
          }
        });
      }
    }
    return () => {
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [isLoaded, loadError]);

  const handleSave = async () => {
    try {
      // const keys = await fetchKeys();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/user/set_fields`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
        },
        body: JSON.stringify({
          user_id: userAttributes.sub,
          phone,
          address,
          company,
          notes,
          subscribe,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to save user fields");
      }
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        phone,
        address,
        company,
        notes,
        subscribe,
      }));
      setSaveClicked(true);
      setTimeout(() => setSaveClicked(false), 1000);  // Reset after 1 second
    } catch (error) {
      console.error("Error saving user fields:", error);
    }
  };

  const handleRegenerateApiKey = async () => {
    try {
      // const keys = await fetchKeys();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER}/user/new_apikey`,
        {
          method: 'POST',
          headers: {
            "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
          },
          body: JSON.stringify({ user_id: userAttributes.sub }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setApiKey(data.api_key);
      } else {
        console.error("Failed to regenerate API key");
      }
    } catch (error) {
      console.error("Error regenerating API key:", error);
    }
  };

  const handleCopyApiKey = () => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleDelete = async () => {
    setIsProcessingDelete(true);
    try {
      // const keys = await fetchKeys();
      
      // First, delete from your backend
      const backendResponse = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/user/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
        },
        body: JSON.stringify({ user_id: userAttributes.sub }),
      });

      if (!backendResponse.ok) {
        throw new Error("Failed to delete user from backend");
      }

      // Then, delete from Cognito
      await Auth.deleteUser();

      // Sign out the user
      await Auth.signOut();

      // Redirect to login page or home page
      setLocation('/');
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsProcessingDelete(false);
      setDeleteModalOpen(false);
    }
  };

  return (
    <div className="user-info-page">
      <h2>Profile Details</h2>
      <div className="content-container">
        <div className="user-info-container">
          <div className="user-info-grid">
            <div className="user-info-label">
              <strong>Name:</strong>
            </div>
            <div className="user-info-value">{userAttributes.name}</div>

            <div className="user-info-label">
              <strong>Email:</strong>
            </div>
            <div className="user-info-value">{userAttributes.email}</div>

            <div className="user-info-label">
              <strong>Company:</strong>
            </div>
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              maxLength="40"
            />

            <div className="user-info-label">
              <strong>Phone:</strong>
            </div>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              maxLength="40"
            />

            <div className="user-info-label">
              <strong>City or Address:</strong>
            </div>
            <input
              id="autocomplete-input"
              className="autocomplete-input"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter neighborhood, city, or street address"
              required
            />

            <div className="user-info-label">
              <strong>Credit Balance:</strong>
            </div>
            <div className="user-info-value green">{`$${balance/100}`}</div>

            <div className="user-info-label">
              <strong>API Key:</strong>
            </div>
            <div className="user-info-value api-key-container">
              {apiKey} 
              <CopyToClipboard text={apiKey} onCopy={handleCopyApiKey}>
                <button className={`copy-button ${copySuccess ? 'copied' : ''}`}>
                  <img src="images/copy_icon.png" alt="Copy" />
                </button>
              </CopyToClipboard>
              <button className="regenerate-button" onClick={handleRegenerateApiKey}>
                New
              </button>
            </div>
            <div className="user-info-label">
              <strong>Feedback<br /><br />or<br /><br />Requests?</strong>
            </div>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows="5"
              cols="60"
              maxLength="255"
              style={{ resize: "none" }}
            />
          </div>
          <div className="button-container">
            <div className="action-buttons">
              <button
                onClick={handleSave}
                className={`save-button ${saveClicked ? 'clicked' : ''}`}
              >
                Save
              </button>
              <button
                onClick={() => setDeleteModalOpen(true)}
                className="delete-button"
              >
                Delete Account
              </button>
            </div>
            <label className="subscribe-label">
              Subscribe to (infrequent) updates?
              <input
                type="checkbox"
                checked={subscribe}
                onChange={() => setSubscribe(!subscribe)}
                className="subscribe-checkbox"
              />
            </label>
          </div>

      {deleteModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Account Deletion</h3>
            <p>Are you sure you want to permanently delete your account?</p>
            {isProcessingDelete ? (
              <div className="spinner"></div>
            ) : (
              <>
                <button onClick={handleDelete}>Yes, Delete My Account</button>
                <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
        <div className="info-frame">
          <iframe src="/profile_guide.html" title="Profile Guide" className="info-iframe"></iframe>
        </div>
      </div>
    </div>
  );
};

export default Profile;
