// OAuth2IdpResponse.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'wouter';
import { Auth } from 'aws-amplify';
import fetchUserAttributes from './fetchUserAttributes';
import decrypt_uuid from "./decryptUuid";
// import { fetchKeys } from './fetchKeys';

export const handleAuthenticatedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userAttributes = await fetchUserAttributes(user.username);
    console.log('User Attributes:', userAttributes);

    try {
      // const keys = await fetchKeys();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/user/cognito_create_or_update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-SHARED-KEY': decrypt_uuid(process.env.REACT_APP_RANDO),
        },
        body: JSON.stringify(userAttributes),
      });
      if (!response.ok) {
        throw new Error(`Failed to save "${user.username}" to backend`);
      }
      const data = await response.json();
      console.log(`User "${user.username}" saved to backend successfully`);
      return { user, isNewUser: data.new_user };
    } catch (error) {
      console.error(`Error saving "${user.username}" to backend:`, error);
      throw error;
    }
  } catch (error) {
    console.error('Error handling authenticated user', error);
    throw error;
  }
};

const OAuth2IdpResponse = () => {
  const [, navigate] = useLocation();

  useEffect(() => {
    const handleAuthResponse = async () => {
      try {
        const currentUrl = window.location.href;
        console.log("Current URL:", currentUrl);

        const url = new URL(currentUrl);
        const code = url.searchParams.get('code');
        const state = url.searchParams.get('state');

        console.log(`Authorization code: ${code}`);
        console.log(`State: ${state}`);

        if (code) {
          console.log("Authorization code found, proceeding with authentication...");
          await handleAuthenticatedUser();
          localStorage.setItem('isLoggedIn', 'true');
            navigate('/');
            window.location.reload();
        } else {
          console.error('Authorization code not found');
          navigate('/');
        }
      } catch (error) {
        console.error('Error handling auth response', error);
        navigate('/');
      }
    };

    handleAuthResponse();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default OAuth2IdpResponse;