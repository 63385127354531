import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import { useLocation, Router } from 'wouter';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';

// Initialize AWS Amplify
Amplify.configure(awsmobile);

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize('G-E52HCBNX0G');

const TrackPageView = () => {
  const [location] = useLocation();

  useEffect(() => {
    // Track page view
    ReactGA.pageview(location);
  }, [location]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <TrackPageView />
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
