// SignOut.js
import React, { useEffect } from 'react';
import { useLocation } from 'wouter';
import { Auth } from 'aws-amplify';

const SignOut = () => {
  const [, navigate] = useLocation();

  useEffect(() => {
    const signOutUser = async () => {
      try {
        await Auth.signOut();
        navigate('/');
      } catch (error) {
        console.error('Error signing out', error);
      }
    };

    signOutUser();
  }, [navigate]);

  return <div>Signing out...</div>;
};

export default SignOut;
