import decrypt_uuid from "./decryptUuid";
// import { fetchKeys } from './fetchKeys';

const fetchUserAttributes = async (username) => {
  try {
    // const keys = await fetchKeys();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/user/get_attributes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-SHARED-KEY": decrypt_uuid(process.env.REACT_APP_RANDO),
      },
      body: JSON.stringify({ username }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const userAttributes = await response.json();
    return userAttributes;
  } catch (error) {
    console.error('Error fetching user attributes:', error);
    throw error;
  }
};

export default fetchUserAttributes;