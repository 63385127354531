import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
import { useLocation, Route, Switch, Redirect } from 'wouter';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { UserProvider } from './components/UserContext';
import Navbar from './components/Navbar';

// Pages
import Reports from './Reports';
import Profile from './Profile';
import PlaceReport from './PlaceReport';

// Components
import OAuth2IdpResponse, { handleAuthenticatedUser }  from './components/OAuth2IdpResponse';
import SignOut from './components/SignOut';
import AuthenticatedSwaggerUI from './components/AuthenticatedSwaggerUI';

import './styles.css';

Amplify.configure(awsmobile);

const customNavbarContent = `
<span style="color: #0081c6; font-style: italic;">
</span>
`;

const blogPages = [
  { path: "/blog/original", component: Blog2014Page },
  { path: "/blog/feature", component: Blog2024Page },
  { path: "/blog/twocities", component: BlogTwoCitiesPage }
];

const App = () => {
  const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [location, navigate] = useLocation();
  const [debugInfo, setDebugInfo] = useState({});

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
      } finally {
        setIsAuthChecked(true);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    setIsLoggedIn(authStatus === 'authenticated');
    setDebugInfo(prevInfo => ({ 
      ...prevInfo, 
      authStatus, 
      isLoggedIn: authStatus === 'authenticated' 
    }));
  }, [authStatus]);

  useEffect(() => {
    setDebugInfo(prevInfo => ({ ...prevInfo, location }));
  }, [location]);

  const handleLogin = useCallback(async () => {
    console.log('handleLogin called');
    try {
      const { domain, redirectSignIn, responseType } = awsmobile.oauth;
      const clientId = awsmobile.userPoolWebClientId;
      const scope = "email+openid+profile+aws.cognito.signin.user.admin";
      const loginUrl = `https://${domain}/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectSignIn}`;
      console.log('Redirecting to:', loginUrl);
      window.location.href = loginUrl;
    } catch (error) {
      console.error('Error initiating login:', error);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    console.log('handleLogout called');
    try {
      await Auth.signOut();
      setIsLoggedIn(false);
      setDebugInfo(prevInfo => ({ ...prevInfo, logoutCalled: true }));
      window.location.href = '/';

      // const { domain, redirectSignOut, responseType } = awsmobile.oauth;
      // const clientId = awsmobile.userPoolWebClientId;
      // const signOutUrl = `https://${domain}/logout?client_id=${encodeURIComponent(clientId)}&logout_uri=${encodeURIComponent(redirectSignOut)}`;
      // console.log('Redirecting to:', signOutUrl);
      // window.location.href = signOutUrl;
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, []);
  
  useEffect(() => {
    console.log('Auth status changed:', authStatus);
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));

    // Parse the current location
    const currentUrl = new URL(window.location.href);

    if (isLoggedIn && 
      (location.endsWith('/oauth2/idpresponse') || currentUrl.pathname === '/oauth2/idpresponse')) {
      console.log('FROM: /oauth2/idpresponse')
      if (authStatus === 'authenticated') {
        handleAuthenticatedUser().then(user => {
          setDebugInfo(prevInfo => ({ 
            ...prevInfo, 
            user 
          }));
        }).catch(error => {
          console.error('Error fetching authenticated user details:', error);
        });
      }

      navigate('/');
    }

    if (!isLoggedIn && localStorage.getItem('loginRequested') === 'true') {
      localStorage.removeItem('loginRequested');
      handleLogin();
    }

    if (localStorage.getItem('logoutRequested') === 'true') {
      localStorage.removeItem('logoutRequested');
      handleLogout();
    }
  }, [isLoggedIn, location, navigate, handleLogin, handleLogout, authStatus]);

  if (!isAuthChecked) {
    return <div>...</div>;
  }

  return (
    <Authenticator.Provider>
      <div className="container">
        <Navbar 
          isLoggedIn={isLoggedIn} 
          handleLogin={handleLogin} 
          handleLogout={handleLogout}
          customContent={customNavbarContent}
        />
        <div className="content">
          <Switch>
            <Route path="/reports">
              {isLoggedIn ? <Reports /> : <Redirect to="/" />}
            </Route>
            <Route path="/profile">
              {isLoggedIn ? <Profile /> : <Redirect to="/" />}
            </Route>
            <Route path="/place_report">
              {isLoggedIn ? <PlaceReport /> : <Redirect to="/" />}
            </Route>
            <Route path="/api-docs">
              {isLoggedIn ? <AuthenticatedSwaggerUI /> : <Redirect to="/" />}
            </Route>
            <Route path="/oauth2/idpresponse" component={OAuth2IdpResponse} />
            <Route path="/signout" component={SignOut} />
            {blogPages.map((blog) => (
              <Route key={blog.path} path={blog.path} component={blog.component} />
            ))}           
            <Route path="/tos" component={TosPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/">
              <Home />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
    </Authenticator.Provider>
  );
};

function Home() {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ type: 'scrollTo', id: hash }, '*');
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Call it once on mount to handle initial hash

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <iframe 
      ref={iframeRef}
      src="/home.html" 
      title="Home Page Content"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

function Blog2014Page() {
  return (
    <iframe 
      src="/blogs/2014.html" 
      title="Original Post"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

function Blog2024Page() {
  return (
    <iframe 
      src="/blogs/2024.html" 
      title="LHS Feature Post"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

function BlogTwoCitiesPage() {
  return (
    <iframe 
      src="/blogs/twocities.html" 
      title="Two Cities Post"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

function TosPage() {
  return (
    <iframe 
      src="/tos.html" 
      title="Terms of Service"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

function PrivacyPage() {
  return (
    <iframe 
      src="/privacy.html" 
      title="Privacy Policy"
      style={{
        width: '100%', 
        height: 'calc(100vh - var(--navbar-height))', 
        border: 'none',
        overflow: 'hidden',
      }} 
    />
  );
}

const AppWithProvider = () => (
  <UserProvider>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </UserProvider>
);

export default AppWithProvider;