import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "wouter";
import { FaCog, FaBars } from 'react-icons/fa';

// import MessageBubble from './MessageBubble';
// <MessageBubble isLoggedIn={isLoggedIn} />

const styles = {
  navLinks: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  navLinksImg: {
    height: '30px',
    marginRight: '20px',
    position: 'relative',
    top: '-3px',
  },
  navLinksA: {
    marginRight: '1rem',
    textDecoration: 'none',
    color: '#333',
  },
  centerSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  customContent: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  authButtons: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  button: {
    marginLeft: '1rem',
    padding: '0.5rem 1rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loginButton: {
    backgroundColor: '#28a745',
    color: 'white',
  },
  logoutButton: {
    backgroundColor: '#f44336',
    color: 'white',
    display: 'inline-block',
  },
  profileIcon: {
    fontSize: '1.5rem',
    marginLeft: '1rem',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  dropdown: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '120px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1,
    top: '100%',
    left: 0,
    marginTop: '5px',
    borderRadius: '4px',
  },
  dropdownContentVisible: {
    display: 'block',
  },
  dropdownItem: {
    color: '#333',
    padding: '6px 16px',
    textDecoration: 'none',
    display: 'block',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    transition: 'background-color 0.2s',
    lineHeight: '1.2',
  },
  dropdownItemHover: {
    backgroundColor: '#f1f1f1',
  },
  dropdownIndicator: {
    display: 'inline-block',
    width: 0,
    height: 0,
    marginLeft: '5px',
    verticalAlign: 'middle',
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderTop: '4px solid #333',
  },
  mobileMenuIcon: {
    display: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
    marginRight: '20px',
  },
};

function Navbar({ isLoggedIn, handleLogin, handleLogout, customContent }) {
  const [location] = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [dropdownLeaveTimeout, setDropdownLeaveTimeout] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      // Check if the device is touch-capable and has a small screen
      const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      const isSmall = window.matchMedia('(max-width: 768px)').matches;
      setIsSmallScreen(isTouch && isSmall);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const isHomePage = location === '/' || location.startsWith('/#');

  const handleDropdownEnter = () => {
    if (dropdownLeaveTimeout) {
      clearTimeout(dropdownLeaveTimeout);
      setDropdownLeaveTimeout(null);
    }
    setDropdownOpen(true);
  };

  const handleDropdownLeave = () => {
    const timeout = setTimeout(() => {
      setDropdownOpen(false);
    }, 200);
    setDropdownLeaveTimeout(timeout);
  };

  return (
    <nav className="navbar">
      <div style={styles.navLinks}>
        <img src="/images/SiteData.io.logo.clear.png" alt="SiteData.io Logo" style={styles.navLinksImg} />
        {isSmallScreen ? (
          <FaBars style={{...styles.mobileMenuIcon, display: 'block'}} />
        ) : (
          <div 
            style={styles.dropdown}
            onMouseEnter={handleDropdownEnter}
            onMouseLeave={handleDropdownLeave}
          >
            <a href="/" style={styles.navLinksA}>
              Home
              <span style={styles.dropdownIndicator}></span>
            </a>
            {isHomePage && (
              <div style={{...styles.dropdownContent, ...(dropdownOpen ? styles.dropdownContentVisible : {})}}>
                {['Product', 'Roadmap', 'Blog', 'About'].map((item) => (
                  <a 
                    key={item}
                    href={`/#${item.toLowerCase()}`}
                    style={{
                      ...styles.dropdownItem,
                      ...(hoveredItem === item ? styles.dropdownItemHover : {})
                    }}
                    onMouseEnter={() => setHoveredItem(item)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {item}
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
        {isLoggedIn && !isSmallScreen && <Link href="/reports" style={styles.navLinksA}>Reports</Link>}
        {isLoggedIn && !isSmallScreen && <Link href="/api-docs" style={styles.navLinksA}>API Docs</Link>}
      </div>
      <div style={styles.centerSection}>
        {customContent && (
          <div style={styles.customContent} dangerouslySetInnerHTML={{ __html: customContent }} />
        )}
      </div>
      <div style={styles.authButtons}>
        {!isLoggedIn && !isSmallScreen && (
          <button 
            id="loginButton" 
            onClick={handleLogin} 
            style={{...styles.button, ...styles.loginButton}}
          >
            Start
          </button>
        )}
        {isLoggedIn && !isSmallScreen && (
          <>
            <Link href="/profile" title="Your Profile Details">
              <FaCog style={styles.profileIcon} />
            </Link>
            <button 
              id="logoutButton" 
              onClick={handleLogout} 
              style={{...styles.button, ...styles.logoutButton}}
            >
              Sign Out
            </button>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;